import React, { FC, useCallback, useEffect, useState } from "react";
import {
  createFormScope,
  CurrencyField,
  FieldRenderer,
  ListField,
  RadioGroupInput,
  SubmitButton,
  useLocalization,
  PercentageField,
  TextField,
  SymbolPlacement,
  FieldUpdateEventArgs,
  ListItem,
  useFormContext,
} from "@saturn-ui/components";
import {
  PropertyFormErrors,
  PropertyFormState,
  PropertyFormTouched,
  SendPropertyStates,
} from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import {
  ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD,
  propertyFormFieldKeys,
} from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepConstants";
import {
  ownershipJointClientList,
  ownershipSingleClientList,
  propertyMortgageList,
  propertyTypeList,
} from "../../aboutYouStepLists";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../types/AppState";
import "./YouPropertyDetailsComponent.scss";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
  CLIENT_CATEGORY_SINGLE,
} from "../../../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import clsx from "clsx";
import { setActivePropertyIndex } from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepSlice";
import { useAppDispatch } from "../../../../../../redux/store";
import {
  handleIsPropertyMortgageFieldChange,
  handleOwnershipFieldChange,
  handlePercentageClient1FieldChange,
  handlePercentageClient2FieldChange,
} from "./YouPropertyDetailsComponent.utils";
import { postProperty } from "../../../../../../feature/journeySteps/steps/aboutYouStep/thunks";
import { RootState, useRootSelector } from "skipton-features";
import { getErrorRoute } from "../../../../../../helpers";
import {
  HTTP_UNAUTHORIZED_STATUS_CODE,
  HttpRequestError,
} from "skipton-common";
import { useHistory } from "react-router-dom";
import {
  focusOnInputById,
  getFirstErrorKey,
} from "../../../../../../utils/formUtils";

type Props = {
  index: number;
  handleSaveItem: (
    values: PropertyFormState,
    collapseSavedItem?: boolean
  ) => void;
  saveButtonTitle: string;
};

export const YouPropertyDetailsComponent: FC<Props> = ({
  index: selectedIndex,
  handleSaveItem,
  saveButtonTitle,
}) => {
  const history = useHistory() as unknown[];
  const definition = useSelector(
    (state: AppState) =>
      state.configuration.data.definitions.journeySteps.steps.content.aboutYou
        .content.yourPropertyForm.content.fields.content
  );
  const dispatch = useAppDispatch();
  const {
    financialAdvicePage: {
      clientInfo: {
        salutation: {
          [CLIENT_1_SCOPE_NAME]: client1Salutation,
          [CLIENT_2_SCOPE_NAME]: client2Salutation,
        },
        category,
      },
    },
  } = useSelector((state: AppState) => state);
  const localization = useLocalization();
  const { APIUri } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const [sendPropertyState, setSendPropertyState] = useState(
    SendPropertyStates.Uninitialised
  );

  localization.percentSymbolPlacement = SymbolPlacement.Before;

  const formContext = useFormContext<
    PropertyFormState,
    PropertyFormErrors,
    PropertyFormTouched
  >();

  const {
    touched,
    errors,
    values,
    handleFieldUpdate,
    setFieldTouched,
    isValid,
    validateForm,
    updateState,
  } = formContext;

  const propertyScope = createFormScope({
    name: "",
    values,
    errors,
    touched,
    readOnly: false,
    disabled: false,
  });

  const handleOwnershipFieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<ListItem>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      handleOwnershipFieldChange(updateState, setFieldTouched);
    },
    [handleFieldUpdate, setFieldTouched, updateState]
  );

  const handleIsPropertyMortgageFieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<ListItem>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      handleIsPropertyMortgageFieldChange(updateState, setFieldTouched);
    },
    [handleFieldUpdate, setFieldTouched, updateState]
  );

  const handlePercentageClient1FieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<number>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }
      setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM);

      if (args.value === null) {
        return;
      }

      if (category === CLIENT_CATEGORY_SINGLE && args.value === 1) {
        handlePercentageClient1FieldChange(updateState, setFieldTouched);

        return;
      }

      if (category === CLIENT_CATEGORY_JOINT) {
        if (
          values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD] === null
        ) {
          return;
        }

        if (
          args.value +
            (values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD] ?? 0) >=
          1
        ) {
          handlePercentageClient1FieldChange(updateState, setFieldTouched);

          return;
        }
      }
    },
    [handleFieldUpdate, setFieldTouched, updateState, values, touched]
  );

  const handlePercentageClient2FieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<number>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }
      setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM);

      if (
        values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] === null ||
        args.value === null
      ) {
        return;
      }

      if (
        args.value +
          (values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] ?? 0) >=
        1
      ) {
        handlePercentageClient2FieldChange(updateState, setFieldTouched);

        return;
      }
    },
    [handleFieldUpdate, setFieldTouched, updateState, values, touched]
  );

  useEffect(() => {
    dispatch(setActivePropertyIndex(selectedIndex));
  }, []);

  const saveProperty = async () => {
    try {
      setSendPropertyState(SendPropertyStates.Initialising);

      await dispatch(
        postProperty({
          baseUrl: APIUri ?? "",
          property: values,
        })
      ).unwrap();

      dispatch(setActivePropertyIndex(selectedIndex + 1));
      handleSaveItem(values);

      setSendPropertyState(SendPropertyStates.Success);
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setSendPropertyState(SendPropertyStates.Error);
    }
  };

  const onSaveClick = async () => {
    validateForm();
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD);
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD);
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD);
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD);
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD);
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD);
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD);
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD);
    setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM);

    if (isValid) {
      await saveProperty();
    } else {
      const firstErrorKey = getFirstErrorKey(errors, propertyFormFieldKeys);
      if (firstErrorKey) {
        focusOnInputById(firstErrorKey, setFieldTouched);
      }
    }
  };

  const ownershipList =
    category === CLIENT_CATEGORY_SINGLE
      ? ownershipSingleClientList.items
      : ownershipJointClientList(
          client1Salutation?.forename ?? "",
          client2Salutation?.forename ?? ""
        ).items;

  const checkSendPropertyState = (...states: SendPropertyStates[]) =>
    !!states.find((state) => state === sendPropertyState);

  return (
    <div className="your-property-details-component">
      <FieldRenderer
        name={ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD}
        definition={definition[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]}
        scope={propertyScope}
        render={(fieldProps) => (
          <ListField
            {...fieldProps}
            isLarge
            options={propertyTypeList.items || []}
            value={values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]}
            onUpdate={handleFieldUpdate}
          />
        )}
      />
      <div className="your-property-details-component-ownership-wrapper">
        <h3 className="step-sub-title">Ownership</h3>
        {category === CLIENT_CATEGORY_SINGLE ? (
          <p className="paragraph-no-top-margin">
            We need to know how much of this property you own overall. For
            example, you might own the property yourself. Alternatively, you
            might only own part of the property. For example if you own it with
            somebody else like your partner, or you have entered an equity
            release agreement. If this is the case, select ‘Other’ from the
            options below.
          </p>
        ) : (
          <>
            <p className="paragraph-no-top-margin">
              We need to know how much of this property you both own overall.
              For example, you might jointly own it between you. Or one of you
              might own it independently from the other.
            </p>
            <p>
              Alternatively, you might only own part of the property. For
              example if you have entered an equity release agreement. If this
              is the case, select ‘Other’ from the options below.
            </p>
          </>
        )}

        <FieldRenderer
          name={ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD}
          definition={definition[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]}
          scope={propertyScope}
          render={(fieldProps) => (
            <ListField
              {...fieldProps}
              isLarge
              options={ownershipList || []}
              value={values[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]}
              onUpdate={handleOwnershipFieldUpdate}
            />
          )}
        />
      </div>
      {values[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?.id === "Other" && (
        <>
          {category === CLIENT_CATEGORY_SINGLE && (
            <FieldRenderer
              name={ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD}
              definition={{
                ...definition[
                  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD
                ],
                caption:
                  "How much of the property do you own, as a percentage?",
              }}
              scope={propertyScope}
              render={(fieldProps) => (
                <PercentageField
                  {...fieldProps}
                  formatterName="Default"
                  min={0}
                  max={1}
                  isLarge
                  value={
                    values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD]
                  }
                  onUpdate={handlePercentageClient1FieldUpdate}
                />
              )}
            />
          )}
          {category === CLIENT_CATEGORY_JOINT && (
            <div
              className={clsx(
                "your-property-details-component-percentage-wrapper",
                {
                  "has-error":
                    errors?.[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM] &&
                    touched?.[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM],
                }
              )}
            >
              <p className="paragraph-important">
                How much of the property do you own, as a percentage?
              </p>
              {errors?.[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM] &&
                touched?.[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM] && (
                  <div className="error-message">
                    The percentage amounts you’ve provided, about who owns the
                    property, don’t add up to 100%. Please can you check the
                    percentages you’ve put and adjust them.
                  </div>
                )}
              <FieldRenderer
                name={ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD}
                definition={{
                  ...definition[
                    ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD
                  ],
                  caption: `${client1Salutation?.forename ?? ""}`,
                }}
                scope={propertyScope}
                render={(fieldProps) => (
                  <PercentageField
                    {...fieldProps}
                    formatterName="Default"
                    min={0}
                    max={1}
                    isLarge
                    value={
                      values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD]
                    }
                    onUpdate={handlePercentageClient1FieldUpdate}
                  />
                )}
              />
              <FieldRenderer
                name={ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD}
                definition={{
                  ...definition[
                    ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD
                  ],
                  caption: `${client2Salutation?.forename ?? ""}`,
                }}
                scope={propertyScope}
                render={(fieldProps) => (
                  <PercentageField
                    {...fieldProps}
                    formatterName="Default"
                    min={0}
                    max={1}
                    isLarge
                    value={
                      values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD]
                    }
                    onUpdate={handlePercentageClient2FieldUpdate}
                  />
                )}
              />
            </div>
          )}
        </>
      )}
      <FieldRenderer
        name={ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD}
        definition={definition[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]}
        scope={propertyScope}
        render={(fieldProps) => (
          <CurrencyField
            {...fieldProps}
            formatterName="Default"
            currencyCode={localization.defaultCurrencyCode}
            min={0}
            isLarge
            value={values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]}
            onUpdate={handleFieldUpdate}
          />
        )}
      />
      {values[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?.id === "Other" && (
        <>
          {category === CLIENT_CATEGORY_SINGLE &&
            values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] !==
              null &&
            (values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] ?? 0) <
              1 && (
              <FieldRenderer
                name={ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD}
                definition={
                  definition[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]
                }
                scope={propertyScope}
                render={(fieldProps) => (
                  <TextField
                    {...fieldProps}
                    isLarge
                    value={
                      values[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]
                    }
                    onUpdate={handleFieldUpdate}
                  />
                )}
              />
            )}
          {category === CLIENT_CATEGORY_JOINT &&
            values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] !==
              null &&
            values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD] !==
              null &&
            (values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] ?? 0) +
              (values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD] ?? 0) <
              1 && (
              <FieldRenderer
                name={ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD}
                definition={
                  definition[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]
                }
                scope={propertyScope}
                render={(fieldProps) => (
                  <TextField
                    {...fieldProps}
                    isLarge
                    value={
                      values[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]
                    }
                    onUpdate={handleFieldUpdate}
                  />
                )}
              />
            )}
        </>
      )}
      <div className="about-you-custom-error-wrapper">
        <fieldset id={ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD}>
          <FieldRenderer
            name={ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD}
            definition={
              definition[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]
            }
            scope={propertyScope}
            render={(fieldProps) => (
              <div className="radio-group-wrapper">
                <span className="radio-group-label">
                  {
                    definition[
                      ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD
                    ].caption
                  }
                </span>
                {errors &&
                  errors[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD] &&
                  touched &&
                  touched[
                    ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD
                  ] && (
                    <div className="error-message">
                      Please answer this question
                    </div>
                  )}
                <RadioGroupInput
                  {...fieldProps}
                  isVertical
                  isLarge
                  value={
                    values[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]
                  }
                  options={propertyMortgageList.items || []}
                  onUpdate={handleIsPropertyMortgageFieldUpdate}
                />
              </div>
            )}
          />
        </fieldset>
      </div>
      {values[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]?.id ===
        "Yes" && (
        <FieldRenderer
          name={ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD}
          definition={
            definition[ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]
          }
          scope={propertyScope}
          render={(fieldProps) => (
            <CurrencyField
              {...fieldProps}
              formatterName="Default"
              currencyCode={localization.defaultCurrencyCode}
              min={0}
              isLarge
              value={values[ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]}
              onUpdate={handleFieldUpdate}
            />
          )}
        />
      )}
      <div className="your-property-details-component-actions">
        <div
          className={clsx("about-you-step-continue-button-wrapper", {
            error: checkSendPropertyState(SendPropertyStates.Error),
          })}
        >
          {checkSendPropertyState(SendPropertyStates.Error) && (
            <div className="error-message">
              Something went wrong. Please try again.
            </div>
          )}
          <SubmitButton
            className="submit-button decorated-button"
            onClick={onSaveClick}
            variant="outlined"
            id="save-button"
            disableRipple={true}
            color="primary"
            submitting={checkSendPropertyState(SendPropertyStates.Initialising)}
          >
            <span className="decoration">{saveButtonTitle}</span>
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};
