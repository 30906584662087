import React, { FC, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./styles/Global.scss";
import {
  ConfigurationProvider,
  LocalizationConfiguration,
  LocalizationProvider,
} from "@saturn-ui/components";
import {
  useRootSelector,
  RootState,
  setAdobeAnalyticsLoaded,
  setOneTrustLoaded,
} from "skipton-features";
import {
  getAboutAdviserRoute,
  getAboutYouRoute,
  getCookiePolicyRoute,
  getDefaultRoute,
  getErrorRoute,
  getFinancialAdviceRoute,
  getOurAdviceRoute,
  getThankYouRoute,
  getYourFeelingsRoute,
  getYourMoneyRoute,
} from "./helpers";
import { AppState } from "./types/AppState";
import { useSelector } from "react-redux";
import { WelcomePage } from "./components/welcomePage/WelcomePage";
import { CookiePolicyPage } from "./components/cookiePolicyPage/CookiePolicyPage";
import { ProtectedRoute } from "./components/protectedRoute/ProtectedRoute";
import FinancialAdvicePage from "./components/financialAdvicePage/FinancialAdvicePage";
import AboutAdviserPage from "./components/aboutAdviserPage/AboutAdviserPage";
import { useRootDispatch } from "./fsg-saturn-skipton-customer-fact-find";
import { OurAdviceStep } from "./components/journeySteps/steps";
import { useAppDispatch } from "./redux/store";
import { setVideoIntegration } from "./feature/videoIntegration/VideoIntegrationSlice";
import { AboutYouStep } from "./components/journeySteps/steps/aboutYouStep/AboutYouStep";
import { YourFeelingsStep } from "./components/journeySteps/steps/yourFeelingsStep/YourFeelingsStep";
import { ErrorPage } from "./components/errorPage/ErrorPage";
import ThankYouPage from "./components/thankYouPage/ThankYouPage";
import { YourMoneyStep } from "./components/journeySteps/steps/yourMoneyStep/YourMoneyStep";
import * as H from "history";

export const AppContent: FC = () => {
  const rootDispatch = useRootDispatch();
  const dispatch = useAppDispatch();
  const location = useLocation() as H.Location;

  const config = useSelector((state: AppState) => state.configuration.data);
  const { adobeAnalyticsTrackingCode, oneTrustJS, oneTrustID, CFFVideoConfig } =
    useRootSelector((state: RootState) => state.configurationSettings);

  const locale = { id: "en-GB", currency: "GBP" };
  const localizationConfig: LocalizationConfiguration = {
    formatters: {},
  };
  const { welcomePage, journeySteps } = config.definitions;

  const loadAdobeAnalytics = (src: string) => {
    const script = document.createElement("script");

    script.src = src;
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      void rootDispatch(setAdobeAnalyticsLoaded(true));
    };
  };

  const loadOneTrust = (src: string, id: string) => {
    const scriptOneTrust = document.createElement("script");
    const scriptWithFunc = document.createElement("script");

    scriptOneTrust.src = src;
    scriptOneTrust.type = "text/javascript";
    scriptOneTrust.charset = "UTF-8";

    scriptOneTrust.setAttribute("data-domain-script", id);

    scriptWithFunc.type = "text/javascript";
    scriptWithFunc.innerHTML = "function OptanonWrapper() { }";

    const commentStart = document.createComment(
      "OneTrust Cookies Consent Notice start"
    );
    const commentEnd = document.createComment(
      "OneTrust Cookies Consent Notice end"
    );
    document.body.appendChild(commentStart);
    document.body.appendChild(scriptOneTrust);
    document.body.appendChild(scriptWithFunc);
    document.body.appendChild(commentEnd);

    scriptOneTrust.onload = () => {
      void rootDispatch(setOneTrustLoaded(true));
    };
  };

  useEffect(() => {
    if (adobeAnalyticsTrackingCode) {
      loadAdobeAnalytics(adobeAnalyticsTrackingCode);
    }
    if (
      oneTrustJS &&
      oneTrustID &&
      location.pathname !== getCookiePolicyRoute()
    ) {
      loadOneTrust(oneTrustJS, oneTrustID);
    }
    if (CFFVideoConfig) {
      dispatch(setVideoIntegration({ config: CFFVideoConfig }));
    }
  }, []);

  return (
    <ConfigurationProvider config={config}>
      <LocalizationProvider
        config={localizationConfig}
        currencyCode={locale.currency}
        locale={locale.id}
      >
        <Switch>
          <Route path={getDefaultRoute()} exact>
            <WelcomePage definition={welcomePage} />
          </Route>
          <ProtectedRoute path={getFinancialAdviceRoute()} exact>
            <FinancialAdvicePage />
          </ProtectedRoute>
          <ProtectedRoute path={getAboutAdviserRoute()} exact>
            <AboutAdviserPage />
          </ProtectedRoute>
          <ProtectedRoute path={getOurAdviceRoute()} exact>
            <OurAdviceStep
              definition={journeySteps.steps.content.ourAdvice.content}
            />
          </ProtectedRoute>
          <ProtectedRoute path={getAboutYouRoute()} exact>
            <AboutYouStep
              definition={journeySteps.steps.content.aboutYou.content}
            />
          </ProtectedRoute>
          <ProtectedRoute path={getYourFeelingsRoute()} exact>
            <YourFeelingsStep
              definition={journeySteps.steps.content.yourFeelings.content}
            />
          </ProtectedRoute>
          <ProtectedRoute path={getYourMoneyRoute()} exact>
            <YourMoneyStep
              definition={journeySteps.steps.content.yourMoney.content}
            />
          </ProtectedRoute>
          <Route path={getCookiePolicyRoute()}>
            <CookiePolicyPage />
          </Route>
          <Route path={getThankYouRoute()}>
            <ThankYouPage />
          </Route>
          <Route path={getErrorRoute()}>
            <ErrorPage />
          </Route>
          <Redirect from="/*" to="/" />
        </Switch>
      </LocalizationProvider>
    </ConfigurationProvider>
  );
};
